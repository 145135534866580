// @generated by protoc-gen-connect-es v1.6.1
// @generated from file hmm/projects/finance_service.proto (package hmm.projects, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateFinanceReferenceLinkRequest, CreateFinanceReferenceLinkResponse, CreateInvoicesRequest, CreateInvoicesResponse, CreatePurchaseOrderRequest, CreatePurchaseOrderResponse, DeleteFinanceReferenceLinkRequest, DeleteFinanceReferenceLinkResponse, DeletePurchaseOrderRequest, DeletePurchaseOrderResponse, GetFinanceEntityRequest, GetFinanceEntityResponse, GetPurchaseOrderRequest, GetPurchaseOrderResponse, ListFinanceEntitiesRequest, ListFinanceEntitiesResponse, ListFinanceReferencesRequest, ListFinanceReferencesResponse, ListPurchaseOrdersRequest, ListPurchaseOrdersResponse, SyncBillsRequest, SyncBillsResponse, UpdateFinanceReferenceLinkRequest, UpdateFinanceReferenceLinkResponse, UpdatePurchaseOrderRequest, UpdatePurchaseOrderResponse } from "./finance_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service hmm.projects.FinanceService
 */
export const FinanceService = {
  typeName: "hmm.projects.FinanceService",
  methods: {
    /**
     * Invoices
     *
     * @generated from rpc hmm.projects.FinanceService.CreateInvoices
     */
    createInvoices: {
      name: "CreateInvoices",
      I: CreateInvoicesRequest,
      O: CreateInvoicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Bills
     *
     * @generated from rpc hmm.projects.FinanceService.SyncBills
     */
    syncBills: {
      name: "SyncBills",
      I: SyncBillsRequest,
      O: SyncBillsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * References
     *
     * @generated from rpc hmm.projects.FinanceService.ListFinanceReferences
     */
    listFinanceReferences: {
      name: "ListFinanceReferences",
      I: ListFinanceReferencesRequest,
      O: ListFinanceReferencesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.CreateFinanceReferenceLink
     */
    createFinanceReferenceLink: {
      name: "CreateFinanceReferenceLink",
      I: CreateFinanceReferenceLinkRequest,
      O: CreateFinanceReferenceLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.UpdateFinanceReferenceLink
     */
    updateFinanceReferenceLink: {
      name: "UpdateFinanceReferenceLink",
      I: UpdateFinanceReferenceLinkRequest,
      O: UpdateFinanceReferenceLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.DeleteFinanceReferenceLink
     */
    deleteFinanceReferenceLink: {
      name: "DeleteFinanceReferenceLink",
      I: DeleteFinanceReferenceLinkRequest,
      O: DeleteFinanceReferenceLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Purchase Orders
     *
     * @generated from rpc hmm.projects.FinanceService.CreatePurchaseOrder
     */
    createPurchaseOrder: {
      name: "CreatePurchaseOrder",
      I: CreatePurchaseOrderRequest,
      O: CreatePurchaseOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.GetPurchaseOrder
     */
    getPurchaseOrder: {
      name: "GetPurchaseOrder",
      I: GetPurchaseOrderRequest,
      O: GetPurchaseOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.ListPurchaseOrders
     */
    listPurchaseOrders: {
      name: "ListPurchaseOrders",
      I: ListPurchaseOrdersRequest,
      O: ListPurchaseOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.UpdatePurchaseOrder
     */
    updatePurchaseOrder: {
      name: "UpdatePurchaseOrder",
      I: UpdatePurchaseOrderRequest,
      O: UpdatePurchaseOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.DeletePurchaseOrder
     */
    deletePurchaseOrder: {
      name: "DeletePurchaseOrder",
      I: DeletePurchaseOrderRequest,
      O: DeletePurchaseOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Financial Entities
     *
     * @generated from rpc hmm.projects.FinanceService.ListFinanceEntities
     */
    listFinanceEntities: {
      name: "ListFinanceEntities",
      I: ListFinanceEntitiesRequest,
      O: ListFinanceEntitiesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc hmm.projects.FinanceService.GetFinanceEntity
     */
    getFinanceEntity: {
      name: "GetFinanceEntity",
      I: GetFinanceEntityRequest,
      O: GetFinanceEntityResponse,
      kind: MethodKind.Unary,
    },
  }
};

