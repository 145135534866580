// @generated by protoc-gen-es v1.10.0
// @generated from file hmm/projects/finance_service.proto (package hmm.projects, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Scope } from "./scope_pb.js";
import { Date } from "../../google/type/date_pb.js";
import { Finance_Entity, Finance_PurchaseOrderLink, Finance_Reference, Finance_Reference_Type } from "./finance_pb.js";
import { Timestamp } from "../../google/protobuf/timestamp_pb.js";
import { PurchaseOrder, PurchaseOrder_Status } from "./purchase_order_pb.js";

/**
 * Sales Orders
 *
 * @generated from message hmm.projects.CreateInvoicesRequest
 */
export const CreateInvoicesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.CreateInvoicesRequest",
  () => [
    { no: 3, name: "scope_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message hmm.projects.CreateInvoicesResponse
 */
export const CreateInvoicesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.CreateInvoicesResponse",
  () => [
    { no: 1, name: "scopes", kind: "message", T: Scope, repeated: true },
  ],
);

/**
 * @generated from message hmm.projects.SyncBillsRequest
 */
export const SyncBillsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.SyncBillsRequest",
  () => [
    { no: 1, name: "from", kind: "message", T: Date },
  ],
);

/**
 * @generated from message hmm.projects.SyncBillsResponse
 */
export const SyncBillsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.SyncBillsResponse",
  [],
);

/**
 * @generated from message hmm.projects.ListFinanceReferencesRequest
 */
export const ListFinanceReferencesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.ListFinanceReferencesRequest",
  () => [
    { no: 1, name: "reference_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Finance_Reference_Type) },
    { no: 3, name: "created_since", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message hmm.projects.ListFinanceReferencesResponse
 */
export const ListFinanceReferencesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.ListFinanceReferencesResponse",
  () => [
    { no: 1, name: "references", kind: "message", T: Finance_Reference, repeated: true },
  ],
);

/**
 * @generated from message hmm.projects.CreateFinanceReferenceLinkRequest
 */
export const CreateFinanceReferenceLinkRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.CreateFinanceReferenceLinkRequest",
  () => [
    { no: 3, name: "purchase_order_link", kind: "message", T: Finance_PurchaseOrderLink },
  ],
);

/**
 * @generated from message hmm.projects.CreateFinanceReferenceLinkResponse
 */
export const CreateFinanceReferenceLinkResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.CreateFinanceReferenceLinkResponse",
  () => [
    { no: 2, name: "purchase_order_link", kind: "message", T: Finance_PurchaseOrderLink },
  ],
);

/**
 * @generated from message hmm.projects.UpdateFinanceReferenceLinkRequest
 */
export const UpdateFinanceReferenceLinkRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.UpdateFinanceReferenceLinkRequest",
  () => [
    { no: 2, name: "purchase_order_link", kind: "message", T: Finance_PurchaseOrderLink },
  ],
);

/**
 * @generated from message hmm.projects.UpdateFinanceReferenceLinkResponse
 */
export const UpdateFinanceReferenceLinkResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.UpdateFinanceReferenceLinkResponse",
  () => [
    { no: 2, name: "purchase_order_link", kind: "message", T: Finance_PurchaseOrderLink },
  ],
);

/**
 * @generated from message hmm.projects.DeleteFinanceReferenceLinkRequest
 */
export const DeleteFinanceReferenceLinkRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.DeleteFinanceReferenceLinkRequest",
  () => [
    { no: 2, name: "purchase_order_link_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.projects.DeleteFinanceReferenceLinkResponse
 */
export const DeleteFinanceReferenceLinkResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.DeleteFinanceReferenceLinkResponse",
  [],
);

/**
 * Purchase Orders
 *
 * @generated from message hmm.projects.CreatePurchaseOrderRequest
 */
export const CreatePurchaseOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.CreatePurchaseOrderRequest",
  () => [
    { no: 1, name: "purchase_order", kind: "message", T: PurchaseOrder },
  ],
);

/**
 * @generated from message hmm.projects.CreatePurchaseOrderResponse
 */
export const CreatePurchaseOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.CreatePurchaseOrderResponse",
  () => [
    { no: 1, name: "purchase_order", kind: "message", T: PurchaseOrder },
  ],
);

/**
 * @generated from message hmm.projects.GetPurchaseOrderRequest
 */
export const GetPurchaseOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.GetPurchaseOrderRequest",
  () => [
    { no: 1, name: "purchase_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.projects.GetPurchaseOrderResponse
 */
export const GetPurchaseOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.GetPurchaseOrderResponse",
  () => [
    { no: 1, name: "purchase_order", kind: "message", T: PurchaseOrder },
  ],
);

/**
 * @generated from message hmm.projects.ListPurchaseOrdersRequest
 */
export const ListPurchaseOrdersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.ListPurchaseOrdersRequest",
  () => [
    { no: 1, name: "statuses", kind: "enum", T: proto3.getEnumType(PurchaseOrder_Status), repeated: true },
    { no: 2, name: "scope_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scope_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message hmm.projects.ListPurchaseOrdersResponse
 */
export const ListPurchaseOrdersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.ListPurchaseOrdersResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: PurchaseOrder, repeated: true },
  ],
);

/**
 * @generated from message hmm.projects.UpdatePurchaseOrderRequest
 */
export const UpdatePurchaseOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.UpdatePurchaseOrderRequest",
  () => [
    { no: 1, name: "purchase_order", kind: "message", T: PurchaseOrder },
  ],
);

/**
 * @generated from message hmm.projects.UpdatePurchaseOrderResponse
 */
export const UpdatePurchaseOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.UpdatePurchaseOrderResponse",
  () => [
    { no: 1, name: "purchase_order", kind: "message", T: PurchaseOrder },
  ],
);

/**
 * @generated from message hmm.projects.DeletePurchaseOrderRequest
 */
export const DeletePurchaseOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.DeletePurchaseOrderRequest",
  () => [
    { no: 1, name: "purchase_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.projects.DeletePurchaseOrderResponse
 */
export const DeletePurchaseOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.DeletePurchaseOrderResponse",
  [],
);

/**
 * Financial Entities
 *
 * @generated from message hmm.projects.ListFinanceEntitiesRequest
 */
export const ListFinanceEntitiesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.ListFinanceEntitiesRequest",
  () => [
    { no: 1, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.projects.ListFinanceEntitiesResponse
 */
export const ListFinanceEntitiesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.ListFinanceEntitiesResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: Finance_Entity, repeated: true },
  ],
);

/**
 * @generated from message hmm.projects.GetFinanceEntityRequest
 */
export const GetFinanceEntityRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.GetFinanceEntityRequest",
  () => [
    { no: 1, name: "finance_entity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hmm.projects.GetFinanceEntityResponse
 */
export const GetFinanceEntityResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hmm.projects.GetFinanceEntityResponse",
  () => [
    { no: 1, name: "finance_entity", kind: "message", T: Finance_Entity },
  ],
);

